
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

// require('./bootstrap');
import * as bootstrap from 'bootstrap';
import {hasClass, addClass, removeClass, domify, delegateEvent, remove, append, find, child, create} from './utils/dom';
import {each, isObject, values} from './utils/object';

import Litepicker from 'litepicker';
// import Lightpick from 'lightpick';
import intlTelInput from 'intl-tel-input';
import intlTelInputUtilsScript from 'intl-tel-input/build/js/utils';
// import intlTelInput from '../../public/js/intlTelInput';
// import Dropzone from '../../public/js/dropzone';

import Dropzone from 'dropzone';
// import moment from 'moment';
import { DateTime } from "luxon";

// import Leaflet from 'leaflet';

import { Calendar } from '@fullcalendar/core';
import itLocale from '@fullcalendar/core/locales/it';
import dayGridPlugin from '@fullcalendar/daygrid';

import { Splide } from '@splidejs/splide';
// import { Splide } from 'public/js/splide.min.js';
import { Grid } from '@splidejs/splide-extension-grid';

// Config
Dropzone.autoDiscover = false;
// Dropzone.options.toLocaleString('it_IT');
// Dropzone.defaultOptions.dictDefaultMessage = "Rilascia qui le foto";
//  = "Drop files here to upload";

// Dropzone.prototype.defaultOptions.dictFallbackMessage
//  = "Your browser does not support drag'n'drop file uploads.";
//     = "이 브라우저는 파일을 끌어다 놓아 업로드하는 기능을 사용할 수 없습니다.";
// Dropzone.prototype.defaultOptions.dictFallbackText
//  = "Please use the fallback form below to upload your files like in the olden days.";
//     = "파일을 업로드하려면 아래에 있는 고전 업로드 기능을 이용해 주세요.";
// Dropzone.prototype.defaultOptions.dictFileTooBig
//  = "File is too big ({{filesize}}MB). Max filesize: {{maxFilesize}}MB.";
//     = "파일 크기가 너무 큽니다. ({{filesize}}MB) {{maxFilesize}}MB 이하의 파일만 업로드할 수 있습니다.";
// Dropzone.prototype.defaultOptions.dictInvalidFileType
//  = "You can't upload files of this type.";
//     = "이 파일 형식은 업로드할 수 없습니다.";
// Dropzone.prototype.defaultOptions.dictResponseError
//  = "Server responded with {{statusCode}} code.";
//     = "서버가 HTTP 응답 코드 {{statusCode}}을 반환했습니다.";
// Dropzone.prototype.defaultOptions.dictCancelUpload
//  = "Cancel upload";
//     = "업로드 취소";
// Dropzone.prototype.defaultOptions.dictCancelUploadConfirmation
//  = "Are you sure you want to cancel this upload?";
//     = "이 업로드를 정말 취소하시겠습니까?";
// Dropzone.prototype.defaultOptions.dictRemoveFile
//  = "Remove file";
//     = "파일 지우기";
// Dropzone.prototype.defaultOptions.dictRemoveFileConfirmation
//  = null;
//     = null;
// Dropzone.prototype.defaultOptions.dictMaxFilesExceeded
//  = "You can only upload {{maxFiles}} files.";
//     = "{{maxFiles}}개 이하의 파일만 업로드할 수 있습니다.";




/**
 * Font Awesome selective import
 * <i> => <svg> conversion
 */
import { library, dom } from '@fortawesome/fontawesome-svg-core'


// TODO se usa question circle?

// REGULAR
import { faPencil, faCheckCircle, faCommentAltExclamation, faStickyNote, faQuestionCircle, faInfinity, faCheck as faCheckRegular, faArrowRight as faArrowRightRegular, faExclamationTriangle, faMinus, faMoneyBillWave, faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'

// SOLID
import { faPencil as faPencilSolid, faEye, faTrash, faChild, faStore, faUser, faBaby, faCheck, faTimes, faConciergeBell, faDoorClosed, faInfoCircle, faCalendarAlt, faPhone as faPhoneSolid, faMoon, faBolt } from '@fortawesome/pro-solid-svg-icons';
import { faCroissant as faCroissantSolid, faAcorn as faAcornSolid,  faAlicorn as faAlicornSolid, faShuttleVan as faShuttleVanSolid, faBabyCarriage as faBabyCarriageSolid, faHourglassEnd as faHourglassEndSolid, faMale } from '@fortawesome/pro-solid-svg-icons'; //Extras

// LIGHT
import { faEuroSign, faTaxi, faCars, faBusAlt, faSubway, faPhone, faWifi, faWind, faParking, faWasher, faTv, faBabyCarriage, faCocktail, faSmokingBan, faTvRetro, faArrowRight, faCroissant, faShuttleVan, faAlicorn, faCoffee, faHourglassEnd, faAcorn } from '@fortawesome/pro-light-svg-icons';

library.add( // update env icons
    faPencil, faCheckCircle, faCommentAltExclamation, faStickyNote, faQuestionCircle, faInfinity, faPhoneSolid, faArrowRightRegular, faCheckRegular, faExclamationTriangle, faMinus, faMoneyBillWave, faSpinnerThird,
    faPencilSolid, faEye, faPencil, faTrash, faChild, faStore, faUser, faCheck, faTimes, faConciergeBell, faDoorClosed, faInfoCircle, faCalendarAlt,
    faCroissantSolid,  faAlicornSolid, faShuttleVanSolid, faBaby, faBabyCarriageSolid, faHourglassEndSolid, faAcornSolid, faMoon, faBolt, faMale,
    faEuroSign, faTaxi, faCars, faBusAlt, faSubway, faPhone, faWifi, faWind, faParking, faWasher, faTv, faBabyCarriage, faCocktail, faSmokingBan, faTvRetro, faArrowRight, faCroissant, faShuttleVan, faAlicorn, faCoffee, faHourglassEnd, faAcorn
);

// dom.i2svg();
dom.watch();



/**
 * you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


// document.onreadystatechange = function(e)
// {
//     if (document.readyState === 'complete')
//     {
//         //dom is ready, window.onload fires later
//     }
// };
// window.onload = function(e)
// {
//     //document.readyState will be complete, it's one of the requirements for the window.onload event to be fired
//     //do stuff for when everything is loaded
// };

// ((elm_id = 'element_id') => {
//     let elm = document.getElementById(elm_id);
//     if (typeof (elm) != 'undefined' && elm != null) {
//
//     }
// })();

// console.log('window loaded');




// MODAL
((elm_id = 'fillable-modal') => {
    let elm = document.getElementById(elm_id);
    // console.log(elm);

    if (typeof (elm) != 'undefined' && elm != null) {

        // let title = elm.getElementById('fillable-modal-title');
        // let body = elm.getElementById('fillable-modal-body');

        elm.addEventListener('hidden.bs.modal', e =>  {

            // title.innerText = '';
            // body.innerText = '';

            document.getElementById('fillable-modal-title').innerText = '';
            document.getElementById('fillable-modal-body').innerText = '';

        });


        // myModal.on('hidden.bs.modal', function (e) {
        //
        //     elm.getElementById('fillable-modal-title').innerText = '';
        //     elm.getElementById('fillable-modal-body').innerText = '';
        // })
    }
})();




((elm_id = 'reservation-search-form') => {

    let elm = document.getElementById(elm_id);
    if (typeof (elm) != 'undefined' && elm != null) {

        // window.disableLitepickerStyles = true;

        const picker = new Litepicker({
            element: document.getElementById('check-in'),
            elementEnd: document.getElementById('check-out'),
            singleMode: false,
            allowRepick: true,
            numberOfColumns: 2,
            numberOfMonths: 2,
            tooltipText: {"one":"notte","other":"notti"},
            format: 'YYYY-MM-DD',
            // secondField: document.getElementById('check-out'),
            // singleDate: false,
            // disabledDatesInRange: false,
            // tooltipNights: true,
            // footer: true,
            minDate: moment(),
        });
    }
})();


((elm_id = 'smart-search-form') => {

    let elm = document.getElementById(elm_id);
    if (typeof (elm) != 'undefined' && elm != null) {

        const checkInOut = document.getElementById('check-in-out');
        let checkIn = document.getElementById('check-in');
        let checkOut = document.getElementById('check-out');
        // const activateTooltip = document.querySelector('[data-bs-toggle="tooltip"]');
        let checkInElm = document.getElementById('check-in');
        let checkOutElm = document.getElementById('check-out');
        // const activateSearch = document.getElementById('activate-search');
        const smartSearchForm = elm;
        // const smartSearchFieldset = document.getElementById('smart-search-fieldset');
        const adultsQuantity = document.getElementById('adults_quantity');
        const infantsQuantity = document.getElementById('infants_quantity');

        function submitSearch() {

            if (checkInOut.value && adultsQuantity.value && infantsQuantity.value) {

                // document.getElementById('sm-loader').style.visibility = 'visible';
                smartSearchForm.submit();
            }
        }

        const picker = new Litepicker({
            element: checkInOut,
            // element: checkIn.value && checkOut.value ? checkIn.value + ' / ' + checkOut.value : checkInOut,
            // elementEnd: document.getElementById('check-out'),
            singleMode: false,
            // startDate: document.getElementById('check-in').value ? document.getElementById('check-in').value : '',
            startDate: checkIn.value ? DateTime.fromISO(checkIn.value).ts : '',
            // endDate: document.getElementById('check-out').value ? document.getElementById('check-out').value : '',
            endDate: checkOut.value ? DateTime.fromISO(checkOut.value).ts : '',
            allowRepick: false,
            numberOfColumns: 2,
            numberOfMonths: 2,
            tooltipText: {"one":"notte","other":"notti"},
            resetButton: true,
            lockDays: [],
            // autoRefresh: true,

            // secondField: document.getElementById('check-out'),
            // singleDate: false,
            // disabledDatesInRange: false,
            // tooltipNights: true,
            // footer: true,
            delimiter: " / ",
            minDate: DateTime.now(),
            tooltipNumber: (totalDays) => {
                return totalDays - 1;
            },
            setup: (picker) => {
                picker.on('selected', (checkIn, checkOut) => {

                    // checkInElm = document.getElementById('check-in');
                    // checkOutElm = document.getElementById('check-out');

                    checkIn = DateTime.fromJSDate(checkIn.dateInstance).toISODate();
                    checkOut = DateTime.fromJSDate(checkOut.dateInstance).toISODate();

                    if(typeof (checkInElm) != 'undefined' && checkInElm != null && typeof (checkOutElm) != 'undefined' && checkOutElm != null) {

                        // checkInElm.value = checkIn.dateInstance.toISOString();
                        // checkOutElm.value = checkOut.dateInstance.toISOString();
                        // checkInElm.value = DateTime.fromJSDate(checkIn.dateInstance).toISOString();
                        checkInElm.value = checkIn;
                        checkOutElm.value = checkOut;

                    } else {

                        let checkInValue = document.createElement('input');
                        checkInValue.setAttribute('id', 'check-in');
                        checkInValue.setAttribute('name', 'check_in');
                        checkInValue.setAttribute('type', 'hidden');
                        checkInValue.setAttribute('value', checkIn);
                        // checkInValue.setAttribute('value', checkIn.toISODate());
                        let checkOutInput = document.createElement('input');
                        checkOutInput.setAttribute('id', 'check-out');
                        checkOutInput.setAttribute('name', 'check_out');
                        checkOutInput.setAttribute('type', 'hidden');
                        checkOutInput.setAttribute('value', checkOut);
                        // checkOutInput.setAttribute('value', checkOut.toISODate());
                        elm.appendChild(checkInValue);
                        elm.appendChild(checkOutInput);
                    }


                });
                picker.on('clear:selection', () => {

                    if(checkInElm.value !== '' || checkOutElm.value !== '' || checkInOut.value !== '' || checkInOut.getAttribute('placeholder') !== '') {

                        checkInElm.value = '';
                        checkOutElm.value = '';
                        checkInOut.setAttribute('placeholder', 'Check-in / Check-out');

                    }

                });
            },
        });
    }
})();



((elm_id = 'book-now-form') => {

    let elm = document.getElementById(elm_id);
    if (typeof (elm) != 'undefined' && elm != null) {

        // const url = '/search-unavailable-dates' + window.location.search;
        // const url = '/search-unavailable-dates';
        const url = '/search-unavailable-dates/?roomid=' + document.getElementById('room-id').value;
        const checkInOut = document.getElementById('check-in-out');
        let checkIn = document.getElementById('check-in');
        let checkOut = document.getElementById('check-out');
        // const activateTooltip = document.querySelector('[data-bs-toggle="tooltip"]');
        let checkInElm = document.getElementById('check-in');
        let checkOutElm = document.getElementById('check-out');
        // const activateSearch = document.getElementById('activate-search');
        const smartSearchForm = elm;
        // const smartSearchFieldset = document.getElementById('smart-search-fieldset');
        const adultsQuantity = document.getElementById('adults_quantity');
        const infantsQuantity = document.getElementById('infants_quantity');

        function submitSearch() {

            if (checkInOut.value && adultsQuantity.value && infantsQuantity.value) {

                // document.getElementById('sm-loader').style.visibility = 'visible';
                smartSearchForm.submit();
            }
        }

        fetch(url, {
            method: 'GET',
            // body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.querySelector("meta[name='csrf-token']").getAttribute("content")
            }
        })
            // .then(response => response.text())
            .then(response => response.json())
            //            .then(response => console.log('Success:', JSON.stringify(response)))
            .then(response => {

                    const picker = new Litepicker({
                        element: checkInOut,
                        // element: checkIn.value && checkOut.value ? checkIn.value + ' / ' + checkOut.value : checkInOut,
                        // elementEnd: document.getElementById('check-out'),
                        singleMode: false,
                        // startDate: document.getElementById('check-in').value ? document.getElementById('check-in').value : '',
                        startDate: checkIn.value ? DateTime.fromISO(checkIn.value).ts : '',
                        // endDate: document.getElementById('check-out').value ? document.getElementById('check-out').value : '',
                        endDate: checkOut.value ? DateTime.fromISO(checkOut.value).ts : '',
                        allowRepick: false,
                        numberOfColumns: 2,
                        numberOfMonths: 2,
                        tooltipText: {"one": "notte", "other": "notti"},
                        resetButton: true,
                        lockDays: response,
                        // autoRefresh: true,

                        // secondField: document.getElementById('check-out'),
                        // singleDate: false,
                        // disabledDatesInRange: false,
                        // tooltipNights: true,
                        // footer: true,
                        delimiter: " / ",
                        minDate: DateTime.now(),
                        tooltipNumber: (totalDays) => {
                            return totalDays - 1;
                        },
                        setup: (picker) => {

                            picker.on('selected', (checkIn, checkOut) => {

                                // checkInElm = document.getElementById('check-in');
                                // checkOutElm = document.getElementById('check-out');

                                checkIn = DateTime.fromJSDate(checkIn.dateInstance).toISODate();
                                checkOut = DateTime.fromJSDate(checkOut.dateInstance).toISODate();

                                if (typeof (checkInElm) != 'undefined' && checkInElm != null && typeof (checkOutElm) != 'undefined' && checkOutElm != null) {

                                    checkInElm.value = checkIn;
                                    checkOutElm.value = checkOut;

                                } else {

                                    let checkInValue = document.createElement('input');
                                    checkInValue.setAttribute('id', 'check-in');
                                    checkInValue.setAttribute('name', 'check_in');
                                    checkInValue.setAttribute('type', 'hidden');
                                    checkInValue.setAttribute('value', checkIn);
                                    // checkInValue.setAttribute('value', checkIn.toISODate());
                                    let checkOutInput = document.createElement('input');
                                    checkOutInput.setAttribute('id', 'check-out');
                                    checkOutInput.setAttribute('name', 'check_out');
                                    checkOutInput.setAttribute('type', 'hidden');
                                    checkOutInput.setAttribute('value', checkOut);
                                    // checkOutInput.setAttribute('value', checkOut.toISODate());
                                    elm.appendChild(checkInValue);
                                    elm.appendChild(checkOutInput);
                                }


                            });
                            picker.on('clear:selection', () => {

                                if (checkInElm.value !== '' || checkOutElm.value !== '' || checkInOut.value !== '' || checkInOut.getAttribute('placeholder') !== '') {

                                    checkInElm.value = '';
                                    checkOutElm.value = '';
                                    checkInOut.setAttribute('placeholder', 'Check-in / Check-out');

                                }

                            });
                        },
                    });

                }

            )
            // .then(response => picker.reloadOptions())
            .catch(error => console.error('Error:', error))
        ;

    }
})();


// TODO eliminare ció che non serve e creare uno per il picker de room, selettore con date non libere non selezionabili
((elm_id = 'room-smart-search-form') => {
    let elm = document.getElementById(elm_id);
    if (typeof (elm) != 'undefined' && elm != null) {
/*
        const url = '/search-unavailable-dates' + window.location.search;
        let picker = new Lightpick({
            field: document.getElementById('check-in'),
            // format: 'DD-MM-YYYY',
            format: 'YYYY-MM-DD',
            secondField: document.getElementById('check-out'),
            singleDate: false,
//                disabledDatesInRange: false,
            tooltipNights: true,
            footer: true,
            minDate: moment(),

        });

        fetch(url, {
            method: 'GET',
            // body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.querySelector("meta[name='csrf-token']").getAttribute("content")
            }
        })
            // .then(response => response.text())
            .then(response => response.json())
            //            .then(response => console.log('Success:', JSON.stringify(response)))
            .then(response => picker.setDisableDates(response))
            .then(response => picker.reloadOptions())
            .catch(error => console.error('Error:', error))
        ;
        */

    }
})();

// WEBSITE ONLY
((elm_id = 'websiteReservationSummary') => {

    let elm = document.getElementById(elm_id);
    if (typeof (elm) != 'undefined' && elm != null) {

        let reservationSummary = elm;
        let elements = document.querySelectorAll('input[id^="extra_"]');
        const url = "/reservation/update-price" + window.location.search;
//            let extraIds = updateSummary;
        updateSummary();

        elements.forEach((element,index) => {

            element.onchange = updateSummary;
        });

        function updateSummary() {
            let extraIds = [];
            let totalReservationCost = document.getElementById('total-reservation-cost');

            elements.forEach((element,index) => {
                if(element.checked) {
                    extraIds.push(element.value);
                }
            });

            addClass(totalReservationCost,'blink'); // this is automatically removed because the element repainting on every change (could be improved)

            fetch(url, {
                method: 'POST',
                body:  JSON.stringify({ extras: extraIds }),
                headers:  {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': document.querySelector("meta[name='csrf-token']").getAttribute("content")
                }
            })
                .then(response => response.text())
                .then(response => reservationSummary.innerHTML = response);
        }
    }
})();

// ADMIN ONLY
((elm_id = 'reservationSummary') => {

    let elm = document.getElementById(elm_id);
    if (typeof (elm) != 'undefined' && elm != null) {

        function updateSummary() {
            let mainForm = document.querySelector('form[id^="reservation-form-"]');
            let totalReservationCostElm = document.getElementById('total-reservation-cost');
            let data = {
                check_in : mainForm.elements["check_in"].value,
                check_out : mainForm.elements["check_out"].value,
                room_id : mainForm.elements["room_id"].value,
                adults_quantity : mainForm.elements["adults_quantity"].value,
                infants_quantity : mainForm.elements["infants_quantity"].value,
                extraIds : []
            };
            elements.forEach((element,index) => {
                if(element.checked) {
                    data.extraIds.push(element.value);
                }
            });

            addClass(totalReservationCostElm,'blink');

            fetch(url, {
                method: 'POST',
                body: JSON.stringify({ data: data }),
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': document.querySelector("meta[name='csrf-token']").getAttribute("content")
                }
            })
                .then(response => response.text())
                // .then(response => reservationSummary.innerHTML = response);
                .then(response => {
                    // addClass(totalReservationCostElm,'blink');
                    totalReservationCostElm.innerHTML = '€ ' + response;
                    setTimeout(() => {
                        removeClass(totalReservationCostElm,'blink')
                    }, 200);
                });
        }

        let reservationSummary = elm;
        let elements = document.querySelectorAll('input[id^="extra_"]');
        const url = "/admin/reservation/update-price" + window.location.search;
        // const res = document.getElementById('info-total-cost');
//            let extraIds = updateSummary;
        updateSummary();

        elements.forEach((element,index) => {

            element.onchange = updateSummary;
        });

    }
})();




// ADMIN ONLY


function setupBookingConfirmationToggle(elm_id) {

    let elm = document.getElementById(elm_id);

    if (typeof (elm) != 'undefined' && elm != null) {

        let paymentButton = elm.querySelector( '#booking-payment-button');
        let requestButton = elm.querySelector( '#toggle-booking-request-button');

        const paymentUrl = '/admin/reservation/toggle-payment-confirmation/'+ elm.dataset.reservationId;
        const requestUrl = '/admin/reservation/toggle-request-confirmation/'+ elm.dataset.reservationId;

        let togglePaymentConfirmation = (e) => {

            // e.currentTarget.innerHTML = '<i class="fas fa-circle-notch fa-spin"></i>';
            e.currentTarget.innerHTML = '<i class="far fa-spinner-third fa-spin"></i>';

            fetch(paymentUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    // 'X-CSRF-TOKEN': document.querySelector("meta[name='csrf-token']").getAttribute("content")  TODO verificare, csrf token ya añadido via axios?
                }
            })
                .then(response => response.text())
                .then(response => {
                    elm.innerHTML = response;
                    paymentButton = elm.querySelector( '#booking-payment-button'); // Remap event into created dynamic element
                    paymentButton.onclick = togglePaymentConfirmation;
                    requestButton = elm.querySelector( '#toggle-booking-request-button');
                    requestButton.onclick = toggleRequestConfirmation;
                })
            ;
        };

        let toggleRequestConfirmation = (e) => {

            // e.currentTarget.innerHTML = '<i class="fas fa-circle-notch fa-spin"></i>';
            e.currentTarget.innerHTML = '<i class="far fa-spinner-third fa-spin"></i>';

            fetch(requestUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    // 'X-CSRF-TOKEN': document.querySelector("meta[name='csrf-token']").getAttribute("content")  TODO verificare, csrf token ya añadido via axios?
                }
            })
                .then(response => response.text())
                .then(response => {
                    elm.innerHTML = response;
                    requestButton = elm.querySelector( '#toggle-booking-request-button'); // Remap event into created dynamic element
                    requestButton.onclick = toggleRequestConfirmation;
                    paymentButton = elm.querySelector( '#booking-payment-button');
                    paymentButton.onclick = togglePaymentConfirmation;
                })
            ;
        };

    if(paymentButton !== null) { paymentButton.onclick = togglePaymentConfirmation }
    if(requestButton !== null) { requestButton.onclick = toggleRequestConfirmation }

    }
}
((elm_id = 'booking-payment-bar') => setupBookingConfirmationToggle(elm_id) )();





// TODO pasar a JS Admin
((elm_id = 'calendar') => {

    let elm = document.getElementById(elm_id);
    if (typeof (elm) != 'undefined' && elm != null) {

        let calcHeight = function () { return document.documentElement.clientHeight * 0.74 };

        let url = '/admin/current-month-reservations';

        fetch(url, {
            headers: { "Content-Type": "application/json; charset=utf-8" }})
            .then(res => res.json()) // parse response as JSON (can be res.text() for plain response)
            .then(response => {

                let thisMonthReservations = [];
                each( response, ( component, key ) => {

                    thisMonthReservations.push({
                        "title": component.room_name + ' ' + component.user_name,
                        "start": component.check_in,
                        "end": component.check_out,
                        "reservationId":  component.id,
                        // "backgroundColor": '#' + component.associated_color,
                        "backgroundColor": component.confirmed_by_payment_at ? '#' + component.associated_color : '#eee',
                        // "borderColor":  '#' + component.associated_color,
                        "borderColor":  '#aaa',
                        "textColor":  component.confirmed_by_payment_at ? '#ffffff' : '#aaa',
                    })
                });

                let calendar = new Calendar(elm, {
                    locale: itLocale,
                    plugins: [dayGridPlugin],
                    initialView: 'dayGridMonth',
                    headerToolbar: {
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,listWeek'
                    },
                    height: calcHeight(),
                    firstDay: 1,
                    events: thisMonthReservations,
                    eventClick: function (info) {

                        let modal = new bootstrap.Modal(document.getElementById('fillable-modal'));

                        info.el.style.borderColor = '#f66D9b';
                        // modal.find('.modal-title').text(info.event.title);
                        // document.getElementsByClassName('.modal-title').innerHTML = info.event.title;
                        document.getElementById('fillable-modal-title').innerText = info.event.title;


                        fetch('admin/reservations/' + info.event.extendedProps.reservationId, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                'X-CSRF-TOKEN': "{{ csrf_token() }}",
                                "X-Requested-With": "XMLHttpRequest"
                            } // "X-Requested-With" makes the laravel method $request->ajax() working
                        })
                            .then(response => response.text())
                            .then(response => document.getElementById('fillable-modal-body').innerHTML = response)
                            // .then(() => setupBookingConfirmationToggle(document.getElementById('booking-payment-bar').dataset.reservationId))
                            .then(() => setupBookingConfirmationToggle('booking-payment-bar')) // para que funcione la confirmación o no confirmación del pago al vuelo

                            .then(modal.show());
                    },
                    windowResize: function (view) {
                        calendar.setOption('contentHeight', calcHeight);
                    }

                });

                calendar.render();


            })
            .catch(err => {
                console.warn("sorry, there are no results for your search")
            });
    }

})();


((elm_id = 'phone_number') => {
    const elm = document.getElementById(elm_id);
    if (typeof (elm) != 'undefined' && elm != null) {

        let iti = intlTelInput(elm, {
            // any initialisation options go here
            customContainer: "w-100",
            // customContainer: "form-floating w-100",
            initialCountry: "it",
            preferredCountries: ["it", "es", "de", "fr", "gb", "si", "at", "pt", "xa", "hr", "me", "be", "nl", "gr", "mt", "tn", "al", "ma"],
            separateDialCode: true,
            hiddenInput: 'phone_number',
            utilsScript: "/js/intlTelInputUtilsScript.js",
            placeholderNumberType: "MOBILE",
            formatOnDisplay: true, // SET THIS!!!
            // customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
            //     return "e.g. " + selectedCountryPlaceholder;
            // },
        });

        document.querySelector('.iti__selected-flag').tabIndex = -1; // alimina la incomoda selección del prefijo cuando usamos la Tabulación

        elm.addEventListener("input", () => {

            if (typeof intlTelInputUtils !== 'undefined') { // utils are lazy loaded, so must check
                let currentText = iti.getNumber(intlTelInputUtils.numberFormat.E164); // Without international prefix
                // let currentText = iti.getNumber(); // with international prefix
                if (typeof currentText === 'string') { // sometimes the currentText is an object :)

                    // On submit the prefix (fi. +39) is added to the hidden input 'phone_number'
                    iti.setNumber(currentText); // will auto-format because of formatOnDisplay=true
                }
            }

            // INPUT VALIDATION
            if(iti.isValidNumber()) {
                if(hasClass(elm, 'is-invalid')) {
                    removeClass(elm, 'is-invalid')
                }
                if(! hasClass(elm, 'is-valid')) {
                    addClass(elm, 'is-valid')
                }
            } else {
                if(! hasClass(elm, 'is-invalid')) {
                    addClass(elm, 'is-invalid')
                }
                if(hasClass(elm, 'is-valid')) {
                    removeClass(elm, 'is-valid')
                }
            }
        });
    }
})();


// LOOK FOR EXISTING USERS DURING RESERVATION
((elm_id = 'client_search') => {
    let elm = document.getElementById(elm_id);
    if (typeof (elm) != 'undefined' && elm != null) {

        let timer; // per limitare le query durante la pressione dei tasti. se premiamo 4 tasti durante i 500 millisecondi di tomeout una sola ricerca verrá effettuata.
        // let resultBoxContainer = document.getElementById('smart-client-search-container');
        let resultBox = document.getElementById('smart-client-search'); // user search result box
        let phoneNumberInput = document.getElementById('phone_number');
        const clearInputBtn = document.getElementById('clear-field-btn');

        const minCharsToStartSearch = 1;
        const msBetweenQueriesTimeout = 500;

        function fillClientData(c) {

            let fields = Object.keys(c);

            for (let i = 0; i < fields.length; i++) {
                document.getElementById(fields[i]).value = c[fields[i]];
            }

            // phoneNumberInput.dispatchEvent( new Event('change') ); //triggers change event to evaluate the telephone_number so the +XX and the flag are updated
            phoneNumberInput.dispatchEvent( new Event('input') ); //triggers change event to evaluate the telephone_number so the +XX and the flag are updated
        }

        function emptyResultBox() {

            resultBox.innerHTML = "";
        }

        function emptySearchBox() {
            // TODO acabar bien esto, cuando de limpia con el botón, si hacemos focus en el campo de busqueda vuelven a aparecer los resultados anteriormente salidos a raíz de los caracteres insertados
            elm.value = "";
        }

        function populateResults(response) {

            if(response.length) {

                each(response, client => {

                    const btn = domify(`<a type="button" class="btn btn-sm btn-outline-primary rounded-pill m-2">${client.name} ${client.surname}</a>`);

                    btn.addEventListener('click', e => {
                        e.stopPropagation();
                        // console.log('click');
                        fillClientData(client);
                        addClass(resultBox, 'd-none');
                    });

                    resultBox.appendChild(btn);
                    removeClass(resultBox, 'd-none');
                });

            } else {

                const isString = isNaN(elm.value); // string => TRUE; number => FALSE

                if(isString){ // name or surname

                    const btnName = domify(`<a type="button" class="btn btn-sm btn-outline-primary m-2">Use this as name</a>`);
                    const btnSurname = domify(`<a type="button" class="btn btn-sm btn-outline-primary m-2">Use this as surname</a>`);

                    btnName.addEventListener('click', e => {
                        fillClientData({"name" : elm.value})
                    });
                    btnSurname.addEventListener('click', e => {
                        fillClientData({"surname" : elm.value})
                    });

                    resultBox.appendChild(btnName);
                    resultBox.appendChild(btnSurname);

                } else { // telephone

                    const btnTelephone = domify(`<a type="button" class="btn btn-sm btn-outline-primary m-2">Use this as telephone number</a>`);

                    btnTelephone.addEventListener('click', e => {
                        fillClientData({"phone_number" : elm.value})
                    });

                    resultBox.appendChild(btnTelephone);
                }
            }
        }

        function smartClientSearch() {

            const url = '/admin/clients/search/' + elm.value;

            fetch(url, {
                method: 'GET',
                headers:  {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': document.querySelector("meta[name='csrf-token']").getAttribute("content")
                }
            })
                .then(response => response.json())
                .then(response => {

                    emptyResultBox();
                    populateResults(response);
                });
        }

        clearInputBtn.addEventListener('click', e => {
            emptySearchBox();
            emptyResultBox();
        });

        elm.addEventListener('focus', e => {
            if(hasClass(resultBox, 'd-none')) {
                removeClass(resultBox, 'd-none');
            }
        });

        // elm.addEventListener('blur', e => { PROBLEMA PROPAGACION
        //     // e.stopPropagation();
        //     if(!hasClass(resultBox, 'd-none')) {
        //         console.log('blur');
        //         // e.preventDefault();
        //         //     window.setTimeout(()=>{addClass(resultBox, 'd-none');}, 1000)
        //         addClass(resultBox, 'd-none');
        //     }
        // });

        elm.addEventListener('input', (e) => {

            if (elm.value.length === 0) {
                clearTimeout(timer);
                if(!hasClass(resultBox, 'd-none')) {
                    addClass(resultBox, 'd-none');
                }
                return
            }

            if (elm.value.length >= minCharsToStartSearch) {

                clearTimeout(timer);
                timer = setTimeout(smartClientSearch, msBetweenQueriesTimeout);

            } else {

                emptyResultBox();
                // removeClass(resultBox, 'expanded');
            }
        });
    }
})();


// FILTER USERS PANEL ON A SEARCH BASIS
((elm_id = 'client-index') => {

    let elm = document.getElementById(elm_id);
    if (typeof (elm) != 'undefined' && elm != null) {

        let clientFilter = document.getElementById('client-filter');
        // let clientTable = document.getElementById('clients-table-body');
        const clearInputBtn = document.getElementById('clear-field-btn');

        const minCharsToStartSearch = 1;
        const msBetweenQueriesTimeout = 500;

        let timer; // per limitare le query durante la pressione dei tasti. se premiamo 4 tasti durante i 500 millisecondi di tomeout una sola ricerca verrá effettuata.
        let url = '';

        function activateClientInputField(e){
            url = ( e.currentTarget.value.length !== 0 && e.currentTarget.value.length >= minCharsToStartSearch ) ? '/admin/clients/filter/' + e.currentTarget.value : '/admin/clients/';
            // if( e.currentTarget.value.length !== 0 && e.currentTarget.value.length >= minCharsToStartSearch ) {
            //
            //     url = '/admin/clients/filter/' + e.currentTarget.value;
            //
            // } else {
            //
            //     url = '/admin/clients/';
            // }
            clearTimeout(timer);
            timer = setTimeout(adminSmartClientSearch, msBetweenQueriesTimeout);
        }

        function adminSmartClientSearch() {
            fetch(url, {
                method: 'GET',
                headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': document.querySelector("meta[name='csrf-token']").getAttribute("content")
        }
        })
            .then(response => response.text())
            .then(response => {

                document.body.innerHTML = response;

                const clientFilter = document.getElementById('client-filter');
                const clearInputBtn = document.getElementById('clear-field-btn');
                const length = clientFilter.value.length;

                clientFilter.addEventListener('input', activateClientInputField);
                clearInputBtn.addEventListener('click', e => {
                    console.log('test');
                    clientFilter.value = '';
                    clientFilter.dispatchEvent( new Event('input') ); //triggers change event to evaluate the telephone_number so the +XX and the flag are updated

                });
                clientFilter.focus();
                clientFilter.setSelectionRange(length, length); // Positioning the cursor to the end of the string
            });
        }
        clientFilter.addEventListener('input', activateClientInputField);
    }
})();







// https://dropzone.gitbook.io/dropzone/
// https://github.com/dropzone/dropzone/wiki/FAQ NEWER
// https://gitlab.com/meno/dropzone/-/wikis/FAQ

// DROPZONE FOR ROOM MODEL - CREATE
((elm_id = 'room-dropzone-create') => {
    const elm = document.getElementById(elm_id);
    const form = document.getElementById('room-create-form');
    if (typeof (elm) != 'undefined' && elm != null) {

        let dz = new Dropzone(elm, {
            url: '/admin/rooms/media',
            method: 'POST',
            maxFilesize: 4, // MB
            addRemoveLinks: true,
            headers: { 'X-CSRF-TOKEN': document.querySelector("meta[name='csrf-token']").getAttribute("content") },
            thumbnailWidth: 320,
            thumbnailHeight: 212,
            // init: function() {},
            dictDefaultMessage: "Clicca o rilascia qui le foto per caricarle",
            dictFallbackMessage: "Il tuo browser non supporta drag and drop",
            dictFallbackText: "Per favore utilizza il formulario di fallback qui sotto per caricare i file, come ai vecchi tempi.",
            dictFileTooBig: "File troppo grande ({{filesize}}MB). Max filesize: {{maxFilesize}}MB.",
            dictInvalidFileType: "Non è possibile caricare file di questo tipo.",
            dictResponseError: "Il server ha risposto con un codice {{statusCode}}.",
            dictCancelUpload: "Annulla l\'upload",
            dictCancelUploadConfirmation: "Vuoi veramente annullare questo upload?",
            dictRemoveFile: "Rimuovi file",
            dictRemoveFileConfirmation: null,
            dictMaxFilesExceeded: "Puoi effettuare l\'upload di massimo {{maxFiles}} files.",

            renameFile: file => {
                // sanitizing filename
                return new Date().getTime() + '_' + file.name.replace(/\s/g, '_').toLowerCase();
            }
        });

        dz.on("addedfile", file => {});

        dz.on("removedfile", file => {
            form.querySelector(`input[name="images[]"][value="${file.upload.filename}"]`).remove();
            // form.querySelector(`input[name="images[]"][value="${file.name}"]`).remove();
        });

        dz.on("success", (file, response) => {
            // console.log(`Success: ${file.name}`);
            const inputElm = create('input', {
                type: 'hidden',
                name: 'images[]',
                value: file.upload.filename
            });

            form.append(inputElm);
        });
    }
})();


// DROPZONE FOR ROOM MODEL - EDIT
((elm_id = 'room-dropzone-edit-show') => {
    const elm = document.getElementById(elm_id);
    const form = document.getElementById('room-edit-show-form');
    if (typeof (elm) != 'undefined' && elm != null) {

        let imagesData = elm.querySelectorAll("data");
        let dz = new Dropzone(elm, {
                url: '/admin/rooms/media',
                method: 'POST',
                maxFilesize: 4, // MB
                addRemoveLinks: true,
                headers: { 'X-CSRF-TOKEN': document.querySelector("meta[name='csrf-token']").getAttribute("content") },
                thumbnailWidth: 320,
                thumbnailHeight: 212,

                dictDefaultMessage: "Clicca o rilascia qui le foto per caricarle",
                dictFallbackMessage: "Il tuo browser non supporta drag and drop",
                dictFallbackText: "Per favore utilizza il formulario di fallback qui sotto per caricare i file, come ai vecchi tempi.",
                dictFileTooBig: "File troppo grande ({{filesize}}MB). Max filesize: {{maxFilesize}}MB.",
                dictInvalidFileType: "Non è possibile caricare file di questo tipo.",
                dictResponseError: "Il server ha risposto con un codice {{statusCode}}.",
                dictCancelUpload: "Annulla l\'upload",
                dictCancelUploadConfirmation: "Vuoi veramente annullare questo upload?",
                dictRemoveFile: "Rimuovi file",
                dictRemoveFileConfirmation: null,
                dictMaxFilesExceeded: "Puoi effettuare l\'upload di massimo {{maxFiles}} files.",

                renameFile: file => {
                    // sanitizing filename
                    // return new Date().getTime() + '_' + file.name.replace(/\s#\/\//g, '_').toLowerCase();
                    return new Date().getTime() + '_' + file.name.replace(/\s/g, '_').toLowerCase();
                },
                init: function() {

                    // https://github.com/dropzone/dropzone/discussions/1909
                    let callback = null; // Optional callback when it's done
                    let crossOrigin = null; // Added to the `img` tag for crossOrigin handling
                    let resizeThumbnail = false; // Tells Dropzone whether it should resize the image first

                    each(imagesData, (d) => {

                        let mockFile = {
                            name: d.dataset.fileName,
                            size: d.dataset.fileSize,
                            // accepted: true  // required if using 'MaxFiles' option (optional)
                        };
                        this.displayExistingFile(mockFile, d.dataset.fileThumbSrc, callback, crossOrigin, resizeThumbnail);

                        const inputElm = create('input', {
                            type: 'hidden',
                            name: 'images[]',
                            value: mockFile.name
                        });
                        form.append(inputElm);
                    });
                },
            }
        );

        dz.on("addedfile", file => {});

        dz.on("removedfile", file => { //TODO check si de las imagenes que estaban hay que eliminar algúnas, check in backend, probablemente acción ya existente en eloquent. Quizás pluck?

            // The image name have to be taken in a different place, depending on if the file is an existing one or is one the user just uploaded (not saving the new Model)
            const imageFileName = !!file.upload ? file.upload.filename : file.name;

            form.querySelector(`input[name="images[]"][value="${imageFileName}"]`).remove(); // deletes the already associated images
        });

        dz.on("success", (file, response) => {

            const inputElm = create('input', {
                type: 'hidden',
                name: 'images[]',
                value: file.upload.filename
            });

            form.append(inputElm);
        });


    }

})();



// SPLIDE
((elm_id = 'photo-gallery') => {

    let elm = document.getElementById(elm_id);

    // SLIDER
    if (typeof (elm) != 'undefined' && elm != null) {

        // let splide = new Splide( '.splide', {
        new Splide( '.splide', {
            height: '40rem',
            // autoWidth: true,
            cover: true,
            perPage: 2,
            gap: '.5rem',
            type: 'loop',
            lazyLoad: 'nearby',
            // wheel: true,
            grid: {
                // rows: 1,
                // cols: 1,
                dimensions: [ [ 1, 1 ], [ 2, 2 ] ],
                gap : {
                    row: '.5rem',
                    col: '.5rem',
                },
            },
            breakpoints: {
                800: {
                    grid: {
                        rows: 2,
                        cols: 2,
                    },
                },
                600: {
                    grid: false,
                    height: false,
                    perPage: 1,
                    heightRatio:0.6
                },
            }
        } ).mount( { Grid });



        // CLICK EVENT AND MODAL GALLERY
        // splide.on( 'click', function (slide, e) {
        //
        //     console.log(slide);
        //     console.log(e);
        //
        //     let fullScreenSplide = new Splide( '#fullscreen-slider', {
        //
        //         width : '100vw',
        //         heightRatio:0.67,
        //         type: 'loop',
        //         lazyLoad: 'nearby',
        //         preloadPages: 2,
        //         // start: 3
        //
        //     } );
        //
        //     fullScreenSplide.mount();
        //
        //     fullScreenSplide.go();
        //
        //     let modal = new bootstrap.Modal(document.getElementById('fullscreen-slider-modal'));
        //
        //     modal.show();
        //     // fullScreenSplide.refresh();
        //     //TODO no va el primer slide
        //
        // } );

    }

})();
